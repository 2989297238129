<template>
<div class="tgju-widget light">

    <div class="list-widget  list-row bold list-row-title border-0">
        <div class="list-col l-title dot head-color no-border">
            <span>دوستان</span>
        </div>
        <div class="list-col l-item  head-color">
            <i class="uil uil-users-alt"></i>
        </div>
    </div>
    <div v-if="widgetLoad" class="tgju-widget-content full-padding">
        <div class="text-center p-2">
            <div class="widget-loading"></div>
        </div>
    </div>
    <div v-else-if="friends.length" class="tgju-widget-content full-padding">
        <div v-for="friend in friends" :key="friend.id" :class="'user-rows' + ($helpers.userAuth() && loginUsername == friend.following_detail.username ? ' bg-warning' : '')">
            <div class="user-rows-image  hover-popper" data-popper-type="user" data-popper-id="1">
                <img :src="friend.following_detail.avatar ? ($helpers.api_url() +  $helpers.json_pars(friend.following_detail.avatar).name ) : 'https://static.tgju.org/views/default/images/tgju-user-avatar.png'">
            </div>
            <div class="user-rows-inner  hover-popper" data-popper-type="user" data-popper-id="1">
                <router-link :to="{ name: 'ProfileOthers', params: { username: friend.following_detail.username } }">
                    <a href="#" class="title">{{ friend.following_detail.firstname +' '+ friend.following_detail.lastname }}</a>
                    <a href="#" class="subtitle"> @{{ friend.following_detail.username }} </a>
                </router-link>
            </div>
            <div class="user-rows-actions" v-if="$helpers.userAuth() && loginUsername != friend.following_detail.username">
                <a href="#" data-title="تایید درخواست">
                    <span v-if="currentRoute == 'Profile'" class="uil uil-times-circle text-danger" @click.prevent="follow('unfollow',friend.following_detail.username)"></span>
                    <span v-if="currentRoute == 'ProfileOthers'" class="uil uil-plus-circle text-success" @click.prevent="follow('follow',friend.following_detail.username)"></span>
                </a>
            </div>
        </div>
        <router-link :to="currentRoute == 'ProfileOthers' ? { name : 'ProfileOthersFriends' , params: { username: this.$route.params.username }} : { name : 'ProfileFriends' } " class="tgju-widget-more-list">
            مشاهده بیشتر
            <i class="uil uil-angle-left" aria-hidden="true"></i>
        </router-link>
    </div>
    <div v-else class="tgju-widget-content full-padding">
        <div class="text-center p-2">
            <div class="not-found-items">
                <i class="uil uil-exclamation"></i>
                دوستی یافت نشد
            </div>
        </div>
    </div>
</div>
</template>

<style lang="scss" scoped>
.widget-min-box-content {
    .l-title {
        display: flex;
    }

    .edit-note {
        font-size: 14px;
        cursor: pointer;
        margin-right: -3px;
    }
}
</style>

<script>
// این کامپونتت لیست دوستان کاربر را نمایش میدهد
// وضعیت کنونی : غیرفعال
export default {
    name: 'ProfileFriendItems',
    props: ['username'],
    components: {},
    data() {
        return {
            friends: [{
                id: undefined,
                user_id: undefined,
                following_id: undefined,
                following_detail: {
                    avatar: undefined,
                    username: undefined,
                    firstname: undefined,
                    lasttname: undefined,
                },
                updated_at: undefined,
                created_at: undefined,
                length: undefined,
            }],
            currentRoute: this.$router.currentRoute.name,
            loginUsername: undefined,
            widgetLoad: true,
        }
    },
    created() {
        if (this.$helpers.userAuth()) {
            this.loginUsername = this.$store.state.user ?
                this.$store.state.user.username :
                this.$helpers.json_pars(localStorage.getItem('user_data')).username;
        }
    },
    mounted() {
        // در زمان ساخته شدن کامپونتت متد مد نظر را اجرا میکنیم
        this.getFriends();
    },
    methods: {
        // اجرای عملیات و ارسال درخواست فالو یا انفالو کردن
        follow(action, username) {
            let api_data = {
                action: action,
            };

            this.$helpers.makeRequest('GET', '/user/follow/' + username, api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    this.getFriends();
                }

                if (api_response.status == 400) {
                    Vue.prototype.$toast.warn({
                        title: 'توجه',
                        message: 'شما پیش تر این کاربر را دنبال کرده اید'
                    });
                }
            });
        },
        // دریافت لیست دوستان کاربر
        getFriends() {
            let api_data = {
                username: this.username,
                except_mutual_friends: this.$router.currentRoute.name == 'ProfileOthers' ? true : false,
                paginate: false,
            };

            if (!this.$helpers.userAuth()) {
                api_data.except_mutual_friends = false
            }

            this.$helpers.makeRequest('GET', '/user/friends', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);
                if (api_response.data.response.status === 'success') {
                    this.friends = api_response.data.response.detail;
                    this.widgetLoad = false;
                }
            });
        },
    },
}
</script>
